<template>
  <div id="home">
    <section class="section-1">
      <div class="bg-wrapper">
        <div
          class="barcelona-bg"
          style="background-image: url('/imgs/home-01.webp')"
        />
      </div>
      <div class="bg-wrapper">
        <div
          class="madrid-bg"
          style="background-image: url('/imgs/home-02.webp')"
        />
      </div>
      <div class="caption">
        <i class="isologo isologo-lg isologo-white mb-4"></i>
        <h1>{{ $lang("header.title") }}</h1>
        <p v-html="$lang('header.subtitle')" />
        <div class="d-flex mt-5 w-100">
          <button
            class="btn btn-secondary btn-fixed-width text-white"
            @click="$router.push({ name: 'barcelona' })"
          >
            Barcelona
          </button>
          <button
            class="btn btn-tertiary btn-fixed-width ml-auto"
            @click="$router.push({ name: 'madrid' })"
          >
            Madrid
          </button>
        </div>
      </div>
    </section>

    <section class="section-2 decoration-1">
      <div class="container">
        <i class="isologo isologo-white mb-4"></i>
        <h3 class="text-primary">{{ $lang("welcome_spain.title") }}</h3>
        <p>
          {{ $lang("welcome_spain.subtitle") }}
        </p>
        <div class="d-grid mb-6">
          <div>
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/home-04.webp')"
            />
            <h5 class="text-primary">
              {{ $lang("welcome_spain.features.1.title") }}
            </h5>
            <p>{{ $lang("welcome_spain.features.1.desc") }}</p>
          </div>

          <div>
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/home-06.webp')"
            />
            <h5 class="text-primary">
              {{ $lang("welcome_spain.features.2.title") }}
            </h5>
            <p>{{ $lang("welcome_spain.features.2.desc") }}</p>
          </div>

          <div>
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/home-05.webp')"
            />
            <h5 class="text-primary">
              {{ $lang("welcome_spain.features.3.title") }}
            </h5>
            <p>{{ $lang("welcome_spain.features.3.desc") }}</p>
          </div>

          <div>
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/home-03.webp')"
            />
            <h5 class="text-primary">
              {{ $lang("welcome_spain.features.4.title") }}
            </h5>
            <p>{{ $lang("welcome_spain.features.4.desc") }}</p>
          </div>
        </div>

        <button
          class="btn btn-primary px-5"
          @click="$router.push('/student-life/courses')"
        >
          {{ $lang("welcome_spain.button") }}
        </button>
      </div>
    </section>

    <section class="section-3 decoration-3">
      <div class="container">
        <div class="row gap-3">
          <div class="col-md">
            <div
              class="img-container img-container-rounded w-100 h-100"
              style="background-image: url('/imgs/home-07.webp')"
            />
          </div>
          <div class="col-md">
            <i class="isologo mb-4"></i>
            <h4>{{ $lang("welcome_nlc.title") }}</h4>
            <p class="mb-5">{{ $lang("welcome_nlc.subtitle") }}</p>
            <ul class="list-unstyled mb-5">
              <li>
                <div
                  class="img-container icon"
                  style="background-image: url('/svgs/building_icon.svg')"
                />
                <p>{{ $lang("welcome_nlc.features.1") }}</p>
              </li>
              <li>
                <div
                  class="img-container icon"
                  style="background-image: url('/svgs/clock_icon.svg')"
                />
                <p>{{ $lang("welcome_nlc.features.2") }}</p>
              </li>
              <li>
                <div
                  class="img-container icon"
                  style="background-image: url('/svgs/book_icon.svg')"
                />
                <p>{{ $lang("welcome_nlc.features.3") }}</p>
              </li>
              <li>
                <div
                  class="img-container icon"
                  style="background-image: url('/svgs/textcloud_icon.svg')"
                />
                <p>{{ $lang("welcome_nlc.features.4") }}</p>
              </li>
            </ul>

            <button
              class="btn btn-dark btn-block"
              @click="$router.push('/contact-us')"
            >
              {{ $lang("welcome_nlc.button") }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="section-4 bg-light">
      <div class="container">
        <h2 class="mb-5">{{ $lang("certifications") }}</h2>

        <div class="swiper-container">
          <swiper
            :breakpoints="swiperBreakpoints"
            :modules="modules"
            pagination
          >
            <swiper-slide>
              <div class="card">
                <div class="card-body">
                  <div
                    class="img-container"
                    style="
                      background-image: url('/imgs/certifications/camara-madrid.webp');
                    "
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card">
                <div class="card-body">
                  <div
                    class="img-container"
                    style="
                      background-image: url('/imgs/certifications/ccse-cadiz.webp');
                    "
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card">
                <div class="card-body">
                  <div
                    class="img-container"
                    style="
                      background-image: url('/imgs/certifications/dele-logo.webp');
                    "
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card">
                <div class="card-body">
                  <div
                    class="img-container"
                    style="
                      background-image: url('/imgs/certifications/Erasmus+_Logo.svg.webp');
                    "
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card">
                <div class="card-body">
                  <div
                    class="img-container"
                    style="
                      background-image: url('/imgs/certifications/Siele.webp');
                    "
                  />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <div class="section-5">
      <div class="row no-gutters">
        <div class="col-md">
          <div
            class="img-container"
            style="background-image: url('/imgs/home-09.webp')"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="p-5 card card-secondary">
                <h4 class="mb-3 text-tertiary">
                  {{ $lang("campuses.barcelona.title") }}
                </h4>
                <div v-html="$lang('campuses.barcelona.desc')" />
                <button
                  class="btn btn-dark btn-block"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("campuses.barcelona.button") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div
            class="img-container"
            style="background-image: url('/imgs/home-10.webp')"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="p-5 card card-tertiary text-right">
                <h4 class="mb-3 text-secondary">
                  {{ $lang("campuses.madrid.title") }}
                </h4>
                <div v-html="$lang('campuses.madrid.desc')" />

                <button
                  class="btn btn-dark btn-block"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("campuses.madrid.button") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section-6 decoration-2">
      <div class="container">
        <div class="row">
          <div class="col-lg">
            <i class="isologo mb-3"></i>
            <h3 class="mb-4">{{ $lang("courses.title") }}</h3>
            <p>{{ $lang("courses.subtitle") }}</p>
          </div>
          <div class="col-lg">
            <div class="card card-primary-shade text-center">
              <div class="card-header p-4">
                <h5 class="m-0">{{ $lang("courses.features.1.title") }}</h5>
              </div>
              <div class="card-body p-4">
                <p>{{ $lang("courses.features.1.subtitle") }}</p>
                <button
                  class="btn btn-dark btn-block mt-4 btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("courses.features.1.button") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg">
            <div class="card card-secondary-shade text-center">
              <div class="card-header p-4">
                <h5 class="m-0">{{ $lang("courses.features.2.title") }}</h5>
              </div>
              <div class="card-body p-4">
                <p>{{ $lang("courses.features.2.subtitle") }}</p>
                <button
                  class="btn btn-dark btn-block mt-4 btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("courses.features.2.button") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg">
            <div class="card card-tertiary text-center">
              <div class="card-header p-4">
                <h5 class="m-0">{{ $lang("courses.features.3.title") }}</h5>
              </div>
              <div class="card-body p-4">
                <p>{{ $lang("courses.features.3.subtitle") }}</p>
                <button
                  class="btn btn-dark btn-block mt-4 btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("courses.features.3.button") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-7 decoration-3">
      <div class="d-md-none w-100" style="position: relative">
        <div class="img-container face-1 size-1" />
        <div class="img-container face-2 size-2" />
        <div class="img-container face-3 size-1" />
      </div>
      <div class="container">
        <div class="caption">
          <i class="isologo mb-4"></i>
          <h3>{{ $lang("support.title") }}</h3>
          <p>{{ $lang("support.subtitle") }}</p>
          <a
            class="btn btn-primary"
            target="_blank"
            href="http://wa.me/34634131769"
          >
            {{ $lang("support.button") }}
          </a>
        </div>

        <div class="img-container face-1 size-2 d-none d-md-block" />
        <div class="img-container face-2 size-3 d-none d-md-block" />
        <div class="img-container face-3 size-1 d-none d-md-block" />
        <div class="img-container face-4 size-1 d-none d-md-block" />
        <div class="img-container face-5 size-2 d-none d-md-block" />
        <div class="img-container face-6 size-3 d-none d-md-block" />
      </div>
    </section>

    <section class="section-8 decoration-3">
      <div class="container">
        <i class="isologo mb-4 mx-auto"></i>
        <h4>{{ $lang("student_life.title") }}</h4>
        <p class="mb-5">{{ $lang("student_life.subtitle") }}</p>

        <div class="row">
          <div class="col-md-6 col-lg mb-3">
            <div class="card card-secondary-shade">
              <div
                class="img-container"
                style="
                  background-image: url('/imgs/student_life/accommodation.webp');
                "
              />
              <div class="card-body">
                <h5>{{ $lang("student_life.features.1.title") }}</h5>
                <button
                  class="btn btn-dark btn-block btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("student_life.features.1.button") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg mb-3">
            <div class="card card-secondary-shade">
              <div
                class="img-container"
                style="background-image: url('/imgs/student_life/visa.webp')"
              />
              <div class="card-body">
                <h5>{{ $lang("student_life.features.2.title") }}</h5>
                <button
                  class="btn btn-dark btn-block btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("student_life.features.2.button") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg mb-3">
            <div class="card card-secondary-shade">
              <div
                class="img-container"
                style="
                  background-image: url('/imgs/student_life/insurance.webp');
                "
              />
              <div class="card-body">
                <h5>{{ $lang("student_life.features.3.title") }}</h5>
                <button
                  class="btn btn-dark btn-block btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("student_life.features.3.button") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg mb-3">
            <div class="card card-secondary-shade">
              <div
                class="img-container"
                style="background-image: url('/imgs/student_life/exams.webp')"
              />
              <div class="card-body">
                <h5>{{ $lang("student_life.features.4.title") }}</h5>
                <button
                  class="btn btn-dark btn-block btn-sm"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("student_life.features.4.button") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";
import { Pagination } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Pagination],
    swiperBreakpoints: {
      0: { slidesPerView: 2 },
      768: { slidesPerView: 4 },
    },
  }),
};
</script>
